<template>
  <div>
    <!-- 搜索 -->
    <div class="bgfff search-wrap">
      <van-search
        v-model="name"
        :left-icon="searchIcon"
        placeholder="请输入场所名称或区域"
        @blur="searchBlur"
      />
    </div>

    <van-list
      :offset="300"
      v-model="loading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
      class="site-list-wrap"
      v-if="siteList.length"
    >
      <div
        v-for="(item, index) in siteList"
        :key="`${item.id}${index}`"
        @click="goDetail(item)"
        class="site-item dfr"
      >
        <!-- left img -->
        <div class="h100 site-item-img">
          <img :src="item.cover_image" alt="" class="w100 h100" />
        </div>
        <!-- right -->
        <div class="ml28 dfc main-between flex1">
          <div class="dfc">
            <div class="fs30 fb fc333 site-item-name-address">
              {{ item.name }}
            </div>
            <div class="fs24 fc666 mt8 site-item-name-address">
              {{ item.address }}
            </div>
          </div>
          <div class="dfc">
            <div class="site-item-price dfr cross-end">
              <div class="fs24 mb2">￥</div>
              <div class="fs36 mr4">{{ item.per_capita }}</div>
              <div class="fs24 mb4">/人</div>
            </div>
            <div class="dfr cross-center mt12 desc-box">
              <div
                v-for="(_item, _index) in item.meeting_space_tags"
                :key="_index"
                class="site-item-desc mr12"
              >
                <div class="fs20 fc666">
                  {{ _item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 取消loading提示 -->
      <div slot="loading"></div>
    </van-list>
    <empty v-else content="暂无场所推荐"></empty>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      name: "", //搜索关键字
      searchIcon: require("../assets/images/search.png"),
      // 上拉加载
      loading: false,
      finished: false,
      page: 1,
      pageSize: 8,
      currentTotal: "",
      siteList: [],
      isSelect: false,
      isReplace: 0
    };
  },
  created() {
    this.isReplace = parseInt(this.$route.query.isReplace) || 0;
    this.initlist();
  },
  // 缓存页面的生命周期激活
  activated() {
    let isSelect = this.$route.query.select;
    if (isSelect) {
      this.isSelect = true;
      this.$title("场所推荐");
    } else {
      this.isSelect = false;
      this.$title("场所列表");
    }
    let scrollTop = this.$localStore.get("siteScrollTop");
    window.scrollTo(0, scrollTop);
  },
  // 离开该页面时出发的卫士
  beforeRouteLeave(to, from, next) {
    const getScrollTop = () => {
      var scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    };
    let scrollTop = getScrollTop();
    this.$localStore.set("siteScrollTop", scrollTop);
    next();
  },
  mounted() {},
  methods: {
    async initlist() {
      this.page = 1;
      this.siteList = await this.getList();
      if (this.currentTotal < this.pageSize) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = false;
        this.finished = false;
      }
      this.loading = false;
    },

    // 获取列表
    async getList() {
      let list = [];
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.name
      };
      const resp = await this.$API
        .get("siteList", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.currentTotal = resp.data.list.length;
        list = resp.data.list;
      }
      return list;
    },

    // 触发加载
    async onLoad() {
      if (!this.finished) {
        this.page++;
        const list = await this.getList();
        if (list.length < this.pageSize) {
          this.siteList = [...this.siteList, ...list];
          this.loading = false;
          this.finished = true;
        } else {
          this.siteList = [...this.siteList, ...list];
          this.loading = false;
          this.finished = false;
        }
      }
    },

    // goDetail
    goDetail(item) {
      if (this.isSelect) {
        const data = this.$localStore.get("meetingData");
        data.placeName = item.name;
        data.placeAddress = item.address;
        data.placeId = item.id;
        this.$localStore.set("meetingData", data);
        this.$goReplace("createMeeting", { isReplace: this.isReplace });
      } else {
        this.$go("siteDetail", { id: item.id });
      }
    },

    // 搜索
    searchBlur() {
      this.initlist();
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.search-wrap {
  position: sticky;
  top: 0;
}
.site-list-wrap {
  padding-bottom: 0.4rem;
  .site-item {
    height: 1.96rem;
    margin-top: 0.36rem;
    padding: 0 0.36rem;
    .site-item-img {
      width: 2.48rem;
      border-radius: 0.08rem;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    }
    .site-item-name-address {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .site-item-price {
      color: #cb9025;
    }
    .desc-box {
      max-width: 4.02rem;
      overflow-x: auto;
      white-space: nowrap;
      .site-item-desc {
        background: #f6f6f6;
        border-radius: 0.04rem;
        padding: 0.08rem 0.16rem;
      }
      .site-item-desc:last-child {
        margin-right: 0;
      }
    }
  }
  .site-item:first-child {
    margin-top: 0.16rem;
  }
}
</style>
